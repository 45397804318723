.App {
  text-align: center;
}

p {
  max-width: 30em;
  padding-bottom: 1em;
  word-wrap: break-word;
  hyphens: auto;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

main {
  text-align: left;
  margin: 1em;
}

body {
  margin: 1.5em;
}

h1 {
  font-size: 150%;
  font-weight: bolder;
  color: teal;
  padding-bottom: 0.5em;
}

em {
  color: orangered;
}

.bottomPadding {
  padding-bottom: 1.0em;
}

.foodImage {
  width: 20em;
}

.photoAttribution {
  font-size: 50%;
  color: gray;
}

.elbowRoom {
  margin: 1.5em;
  max-width: 95%;
}